<template>
  <NuxtLayout>
    <BreadcrumbNav :title="'Home'" :description="'Montana\'s citizen-powered local news and opinion.'" />
    <div class="grid divide-y dark:divide-gray-800 border-b border-gray-200 dark:border-gray-800">
      <client-only>
        <section class="grid bg-gray-50 dark:bg-gray-950 py-3 sm:py-0">
          <div class="flex flex-col sm:flex-row gap-2 px-4 pt-2 pr-4">
            <hgroup class="flex flex-col sm:flex-row sm:items-center sm:gap-4 flex-1">
              <h2 class="font-bold text-2xl mb-0 leading-none text-nowrap">
                <UIcon name="i-heroicons-megaphone" class="w-6 h-6 align-top text-stone-400" />
                Voices
              </h2>
              <p class="text-gray-400 text-sm mt-1.5">
                Montanan's sharing what's new.
                <UBadge variant="soft" color="gray">
                  <NuxtLink to="compose" class="underline">Post your Voice</NuxtLink>
                </UBadge>
              </p>
            </hgroup>
            <UTabs :items="tabItems" v-model="voicesTab" class="-mb-2 sm:mb-0">
              <template #default="{ item }">
                <UIcon :name="item.icon" class="w-4 h-4 self-center" />
                <span class="inline-block pl-1">{{ item.label }}</span>
              </template>
            </UTabs>
          </div>
          <div class="grid">
            <SkeletonVoiceCarousel v-if="['pending', 'idle'].includes(voicesStatus) && voices.length === 0" class="mt-2" />
            <UCarousel
              v-else
              v-slot="{ item }"
              :items="voices"
              :ui="useCarouselUi"
              class="overflow-hidden"
              indicators
            >
              <VoiceCard :voice="item" />
            </UCarousel>
            <UButton to="/voices" color="white" class="justify-self-end mr-4 mt-3 sm:-mt-3 sm:mb-3">More →</UButton>
          </div>
        </section>
        <section class="grid py-3 sm:py-0">
          <div class="flex flex-col sm:flex-row gap-2 px-4 pt-2 pr-3">
            <hgroup class="flex flex-col sm:flex-row sm:items-center sm:gap-4 flex-1">
              <h2 class="font-bold text-2xl mb-0 leading-none text-nowrap">
                <UIcon name="i-heroicons-newspaper" class="w-6 h-6 align-top text-stone-400" />
                Stories
              </h2>
              <p class="text-gray-400 text-sm mt-1.5 leading-tight">
                Local happenings and opinions in Montana.
                <UBadge variant="soft" color="gray"><a href="mailto:letters@westernmt.news" class="underline">Submit Op-Ed</a></UBadge></p>
            </hgroup>
            <UTabs :items="tabItems" v-model="storiesTab" class="-mb-2 sm:mb-0">
              <template #default="{ item }">
                <UIcon :name="item.icon" class="w-4 h-4 self-center" />
                <span class="inline-block pl-1">{{ item.label }}</span>
              </template>
            </UTabs>
          </div>
          <SkeletonStoryCarousel v-if="['pending', 'idle'].includes(storiesStatus) && stories.length === 0" class="mt-2" />
          <UCarousel v-else v-slot="{ item }" :items="stories" class="overflow-hidden relative z-0" indicators :ui="useCarouselUi">
            <StoryCardImage :story="item" />
          </UCarousel>
          <UButton to="/stories" color="white" class="justify-self-end mr-4 mt-3 sm:-mt-3 sm:mb-3">More →</UButton>
        </section>
        <section class="grid bg-gray-50 dark:bg-gray-950 py-3 sm:py-0">
          <div class="flex flex-col sm:flex-row gap-2 px-4 pt-2 pr-4">
            <hgroup class="flex flex-col sm:flex-row sm:items-center sm:gap-4 flex-1">
              <h2 class="font-bold text-2xl mb-0 leading-none text-nowrap">
                <UIcon name="i-heroicons-fire" class="w-6 h-6 align-top text-stone-400" />
                Firehose
              </h2>
              <p class="text-gray-400 text-sm mt-1.5">
                Everything. A flood of local and national news.
              </p>
            </hgroup>
            <UTabs :items="tabItems" v-model="firehoseTab" class="-mb-2 sm:mb-0">
              <template #default="{ item }">
                <UIcon :name="item.icon" class="w-4 h-4 self-center" />
                <span class="inline-block pl-1">{{ item.label }}</span>
              </template>
            </UTabs>
          </div>
          <div class="grid">
            <SkeletonVoiceCarousel v-if="['pending', 'idle'].includes(firehoseStatus) && firehose.length === 0" class="mt-2" />
            <UCarousel
              v-else
              v-slot="{ item }"
              :items="firehose"
              :ui="useCarouselUi"
              class="overflow-hidden"
              indicators
            >
              <VoiceCard :voice="item" />
            </UCarousel>
            <UButton to="/firehose" color="white" class="justify-self-end mr-4 mt-3 sm:-mt-3 sm:mb-3">More →</UButton>
          </div>
        </section>
      </client-only>
    </div>
    <template #sidebar>
      <TopicTrending :local="true" />
    </template>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { LVN_API_Posts } from '~/types/interfaces';
import { useStorage } from '@vueuse/core';

const { public: { baseURL } } = useRuntimeConfig()

const storiesTab = useStorage('storiesTab', 0)
const voicesTab = useStorage('voicesTab', 0)
const firehoseTab = useStorage('firehoseTab', 0)

const storiesRestUrl = computed(() => {
  return storiesTab.value === 0 ? '/wp/v2/posts' : '/wordpress-popular-posts/v1/popular-posts'
})

const voicesRestUrl = computed(() => {
  return voicesTab.value === 0 ? '/wp/v2/drop' : '/wordpress-popular-posts/v1/popular-posts'
})

const firehoseRestUrl = computed(() => {
  return firehoseTab.value === 0 ? '/wp/v2/drop' : '/wordpress-popular-posts/v1/popular-posts'
})

const { data: stories, status: storiesStatus, refresh: refreshStories } = await useLazyFetch<LVN_API_Posts>(storiesRestUrl, {
  baseURL,
  query: {
    per_page: 10,
    _fields: 'id,date,slug,title.rendered,excerpt.rendered,author,featured_media,link,embed',
    post_type: 'post',
  },
  default() {
    return []
  },
  server: false,
})

const { data: voices, status: voicesStatus, refresh: refreshVoices } = await useLazyFetch<LVN_API_Posts>(voicesRestUrl, {
  baseURL,
  query: {
    per_page: 10,
    local_only: true,
    _fields: 'id,author,date,content.rendered,link,featured_media,comment_count,reactions',
    post_type: 'drop'
  },
  default() {
    return []
  },
  server: false,
})

const { data: firehose, status: firehoseStatus, refresh: refreshFirehose } = await useLazyFetch<LVN_API_Posts>(firehoseRestUrl, {
  baseURL,
  query: {
    per_page: 10,
    _fields: 'id,author,date,content.rendered,link,featured_media,comment_count,reactions',
    post_type: 'drop'
  },
  default() {
    return []
  },
  server: false,
})

const tabItems = [
  {
    label: 'Newest',
    icon: 'i-heroicons-clock',
  },
  {
    label: 'Popular',
    icon: 'i-heroicons-arrow-trending-up',
  }
]

useIntervalFn(() => {
  refreshStories()
  refreshVoices()
  refreshFirehose()
}, 60000)

useHead({
  title: 'Western Montana News | News, Opinion, Weather, Radio',
  meta: [
    {
      name: 'description',
      content: 'Montana News and Opinion. Montana\'s citizen-powered local news, opinion, weather, and radio.',
    }
  ],
})
</script>
